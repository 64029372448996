<style>
  heading {
    display: block;
    background-color: var(--medium);
    padding: 16px;
    padding-top: 110px;
    border-radius: 5px;
    transform: scale(1.1);
    background-image: url(/svelt-yjs-logo.png);
    /* background-size: 400px 186px; */
    background-size: 275px 64px;
    background-repeat: no-repeat;
    background-position: center 34px;
  }
  subtitle {
    display: block;
    text-align: center;
    font-size: var(--font-regular);
    color: var(--dark);
    margin-bottom: 24px;
  }
  subtitle em {
    font-weight: bold;
    filter: saturate(100%);
  }
  .punch {
    color: var(--punch);
  }
</style>

<heading>
  <subtitle>
    Combining the
    <em>reactivity of Svelte</em>
    with<br />
    the local-first, distributed
    <em class="punch">synchronization of Yjs</em>.
  </subtitle>
</heading>
