<style>
  row {
    display: block;
    margin: 4px 8px;
    min-width: 400px;
    border-bottom: 2px solid white;
  }
</style>

<row>
  <slot />
</row>
